export const mockSchedule = {
	name: 'Schedule #1',
	id: 'scheduleOne',
	domains: ['m2foryou.com', 'mirus-dev-beta.com', 'superflyagentguy.com'],
	calendarSubscriptionURL:
		'https://mysfdomain.com/api/v3/agents/0XDEADBEEFD/calendar/asdflkhj2549hb.ics',
	availabilityBlocks: {
		B18ZOVGPZIE: [
			{
				pk: 0,
				start: '08:00',
				end: '11:00',
				recurrences: [false, true, true, true, true, true, false],
				communicationMediums: ['phone'],
				location: 'Normal',
			},
			{
				pk: 1,
				start: '12:00',
				end: '16:00',
				recurrences: [false, true, true, true, true, true, false],
				communicationMediums: ['phone'],
				location: 'Normal',
			},
		],
		RQBXLIF0TA0: [
			{
				pk: 0,
				start: '09:00',
				end: '11:30',
				recurrences: [false, true, true, true, true, true, false],
				communicationMediums: ['phone'],
				location: 'Normal',
			},
			{
				pk: 1,
				start: '12:30',
				end: '17:00',
				recurrences: [false, true, true, true, true, true, false],
				communicationMediums: ['phone'],
				location: 'Green Bay',
			},
		],
		G1DQZX1ONIG: [
			{
				pk: 0,
				start: '09:00',
				end: '11:00',
				recurrences: [false, true, true, true, true, true, false],
				communicationMediums: ['phone'],
				location: 'Normal',
			},
			{
				pk: 1,
				start: '12:00',
				end: '17:00',
				recurrences: [false, true, true, true, true, true, false],
				communicationMediums: ['phone'],
				location: 'Normal',
			},
		],
		'7YLZCQOAKUG': [
			{
				pk: 0,
				start: '09:00',
				end: '11:00',
				recurrences: [false, true, true, true, true, true, false],
				communicationMediums: ['phone'],
				location: 'Normal',
			},
			{
				pk: 1,
				start: '12:00',
				end: '17:00',
				recurrences: [false, true, true, true, true, true, false],
				communicationMediums: ['phone'],
				location: 'Normal',
			},
		],
		UHO7NNNL8LP: [
			{
				pk: 0,
				start: '09:00',
				end: '11:00',
				recurrences: [false, true, true, true, true, true, false],
				communicationMediums: ['phone'],
				location: 'Normal',
			},
			{
				pk: 1,
				start: '12:00',
				end: '17:00',
				recurrences: [false, true, true, true, true, true, false],
				communicationMediums: ['phone'],
				location: 'Normal',
			},
		],
		AZBHNZ4PJKC: [
			{
				pk: 0,
				start: '09:00',
				end: '11:00',
				recurrences: [false, true, true, true, true, true, false],
				communicationMediums: ['phone'],
				location: 'Normal',
			},
			{
				pk: 1,
				start: '12:00',
				end: '17:00',
				recurrences: [false, true, true, true, true, true, false],
				communicationMediums: ['phone'],
				location: 'Normal',
			},
		],
		'9G1YLUI8RFD': [
			{
				pk: 0,
				start: '09:00',
				end: '11:00',
				recurrences: [false, true, true, true, true, true, false],
				communicationMediums: ['phone'],
				location: 'Normal',
			},
			{
				pk: 1,
				start: '12:00',
				end: '17:00',
				recurrences: [false, true, true, true, true, true, false],
				communicationMediums: ['phone'],
				location: 'Normal',
			},
		],
		JNDF1NSBLDJ: [
			{
				pk: 0,
				start: '09:00',
				end: '11:00',
				recurrences: [false, true, true, true, true, true, false],
				communicationMediums: ['phone'],
				location: 'Normal',
			},
			{
				pk: 1,
				start: '12:00',
				end: '17:00',
				recurrences: [false, true, true, true, true, true, false],
				communicationMediums: ['phone'],
				location: 'Normal',
			},
		],
	},

	// this coverageView should be a range of only the time slots we need to show.
	// the earliest time should be the earliest any employee is available
	// and the lastest should be the latest time any employee is available.
	// it should also always start with a time at the top of the hour and end with
	// a time half way through the hour (makes the labeling possible).
	coverageView: [
		{
			day: 'Sunday',
			timeSlots: [
				{
					beginning: '8:00',
					end: '8:30',
					teamMembersAvailable: 0,
				},
				{
					beginning: '8:30',
					end: '9:00',
					teamMembersAvailable: 0,
				},
				{
					beginning: '9:00',
					end: '9:30',
					teamMembersAvailable: 0,
				},
				{
					beginning: '9:30',
					end: '10:00',
					teamMembersAvailable: 0,
				},
				{
					beginning: '10:00',
					end: '10:30',
					teamMembersAvailable: 0,
				},
				{
					beginning: '10:30',
					end: '11:00',
					teamMembersAvailable: 0,
				},
				{
					beginning: '11:00',
					end: '11:30',
					teamMembersAvailable: 0,
				},
				{
					beginning: '11:30',
					end: '12:00',
					teamMembersAvailable: 0,
				},
				{
					beginning: '12:00',
					end: '12:30',
					teamMembersAvailable: 0,
				},
				{
					beginning: '12:30',
					end: '1:00',
					teamMembersAvailable: 0,
				},
				{
					beginning: '1:00',
					end: '1:30',
					teamMembersAvailable: 0,
				},
				{
					beginning: '1:30',
					end: '2:00',
					teamMembersAvailable: 0,
				},
				{
					beginning: '2:00',
					end: '2:30',
					teamMembersAvailable: 0,
				},
				{
					beginning: '2:30',
					end: '3:00',
					teamMembersAvailable: 0,
				},
				{
					beginning: '3:00',
					end: '3:30',
					teamMembersAvailable: 0,
				},
				{
					beginning: '3:30',
					end: '4:00',
					teamMembersAvailable: 0,
				},
				{
					beginning: '4:00',
					end: '4:30',
					teamMembersAvailable: 0,
				},
				{
					beginning: '4:30',
					end: '5:00',
					teamMembersAvailable: 0,
				},
				{
					beginning: '5:00',
					end: '5:30',
					teamMembersAvailable: 0,
				},
				{
					beginning: '5:30',
					end: '6:00',
					teamMembersAvailable: 0,
				},
			],
		},
		{
			day: 'Monday',
			timeSlots: [
				{
					beginning: '8:00',
					end: '8:30',
					teamMembersAvailable: 1,
				},
				{
					beginning: '8:30',
					end: '9:00',
					teamMembersAvailable: 1,
				},
				{
					beginning: '9:00',
					end: '9:30',
					teamMembersAvailable: 8,
				},
				{
					beginning: '9:30',
					end: '10:00',
					teamMembersAvailable: 8,
				},
				{
					beginning: '10:00',
					end: '10:30',
					teamMembersAvailable: 8,
				},
				{
					beginning: '10:30',
					end: '11:00',
					teamMembersAvailable: 8,
				},
				{
					beginning: '11:00',
					end: '11:30',
					teamMembersAvailable: 1,
				},
				{
					beginning: '11:30',
					end: '12:00',
					teamMembersAvailable: 0,
				},
				{
					beginning: '12:00',
					end: '12:30',
					teamMembersAvailable: 7,
				},
				{
					beginning: '12:30',
					end: '1:00',
					teamMembersAvailable: 8,
				},
				{
					beginning: '1:00',
					end: '1:30',
					teamMembersAvailable: 8,
				},
				{
					beginning: '1:30',
					end: '2:00',
					teamMembersAvailable: 8,
				},
				{
					beginning: '2:00',
					end: '2:30',
					teamMembersAvailable: 8,
				},
				{
					beginning: '2:30',
					end: '3:00',
					teamMembersAvailable: 8,
				},
				{
					beginning: '3:00',
					end: '3:30',
					teamMembersAvailable: 8,
				},
				{
					beginning: '3:30',
					end: '4:00',
					teamMembersAvailable: 8,
				},
				{
					beginning: '4:00',
					end: '4:30',
					teamMembersAvailable: 7,
				},
				{
					beginning: '4:30',
					end: '5:00',
					teamMembersAvailable: 7,
				},
				{
					beginning: '5:00',
					end: '5:30',
					teamMembersAvailable: 0,
				},
				{
					beginning: '5:30',
					end: '6:00',
					teamMembersAvailable: 0,
				},
			],
			teamMembers: [
				{
					name: 'Anissa Quitzon',
					associate_id: 'B18ZOVGPZIE',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: true,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: true,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: false,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: false,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Cyrus Luettgen',
					associate_id: 'RQBXLIF0TA0',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: true,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: false,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Demarcus Huel',
					associate_id: 'G1DQZX1ONIG',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Ed McClure',
					associate_id: '7YLZCQOAKUG',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Hortense Brown',
					associate_id: 'UHO7NNNL8LP',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Jonas Yost',
					associate_id: 'AZBHNZ4PJKC',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Sheridan Hackett',
					associate_id: 'RQBXLIF0TA0',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Watson Streich',
					associate_id: 'RQBXLIF0TA0',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
			],
		},
		{
			day: 'Tuesday',
			timeSlots: [
				{
					beginning: '8:00',
					end: '8:30',
					teamMembersAvailable: 3,
				},
				{
					beginning: '8:30',
					end: '9:00',
					teamMembersAvailable: 3,
				},
				{
					beginning: '9:00',
					end: '9:30',
					teamMembersAvailable: 8,
				},
				{
					beginning: '9:30',
					end: '10:00',
					teamMembersAvailable: 8,
				},
				{
					beginning: '10:00',
					end: '10:30',
					teamMembersAvailable: 8,
				},
				{
					beginning: '10:30',
					end: '11:00',
					teamMembersAvailable: 8,
				},
				{
					beginning: '11:00',
					end: '11:30',
					teamMembersAvailable: 1,
				},
				{
					beginning: '11:30',
					end: '12:00',
					teamMembersAvailable: 0,
				},
				{
					beginning: '12:00',
					end: '12:30',
					teamMembersAvailable: 7,
				},
				{
					beginning: '12:30',
					end: '1:00',
					teamMembersAvailable: 8,
				},
				{
					beginning: '1:00',
					end: '1:30',
					teamMembersAvailable: 8,
				},
				{
					beginning: '1:30',
					end: '2:00',
					teamMembersAvailable: 8,
				},
				{
					beginning: '2:00',
					end: '2:30',
					teamMembersAvailable: 8,
				},
				{
					beginning: '2:30',
					end: '3:00',
					teamMembersAvailable: 8,
				},
				{
					beginning: '3:00',
					end: '3:30',
					teamMembersAvailable: 8,
				},
				{
					beginning: '3:30',
					end: '4:00',
					teamMembersAvailable: 8,
				},
				{
					beginning: '4:00',
					end: '4:30',
					teamMembersAvailable: 7,
				},
				{
					beginning: '4:30',
					end: '5:00',
					teamMembersAvailable: 7,
				},
				{
					beginning: '5:00',
					end: '5:30',
					teamMembersAvailable: 0,
				},
				{
					beginning: '5:30',
					end: '6:00',
					teamMembersAvailable: 0,
				},
			],
			teamMembers: [
				{
					name: 'Anissa Quitzon',
					associate_id: 'B18ZOVGPZIE',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: true,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: true,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: false,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: false,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Cyrus Luettgen',
					associate_id: 'RQBXLIF0TA0',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: true,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: false,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Demarcus Huel',
					associate_id: 'G1DQZX1ONIG',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Ed McClure',
					associate_id: '7YLZCQOAKUG',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Hortense Brown',
					associate_id: 'UHO7NNNL8LP',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Jonas Yost',
					associate_id: 'AZBHNZ4PJKC',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Sheridan Hackett',
					associate_id: 'RQBXLIF0TA0',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Watson Streich',
					associate_id: 'RQBXLIF0TA0',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
			],
		},
		{
			day: 'Wednesday',
			timeSlots: [
				{
					beginning: '8:00',
					end: '8:30',
					teamMembersAvailable: 1,
				},
				{
					beginning: '8:30',
					end: '9:00',
					teamMembersAvailable: 1,
				},
				{
					beginning: '9:00',
					end: '9:30',
					teamMembersAvailable: 8,
				},
				{
					beginning: '9:30',
					end: '10:00',
					teamMembersAvailable: 8,
				},
				{
					beginning: '10:00',
					end: '10:30',
					teamMembersAvailable: 8,
				},
				{
					beginning: '10:30',
					end: '11:00',
					teamMembersAvailable: 8,
				},
				{
					beginning: '11:00',
					end: '11:30',
					teamMembersAvailable: 1,
				},
				{
					beginning: '11:30',
					end: '12:00',
					teamMembersAvailable: 0,
				},
				{
					beginning: '12:00',
					end: '12:30',
					teamMembersAvailable: 7,
				},
				{
					beginning: '12:30',
					end: '1:00',
					teamMembersAvailable: 8,
				},
				{
					beginning: '1:00',
					end: '1:30',
					teamMembersAvailable: 8,
				},
				{
					beginning: '1:30',
					end: '2:00',
					teamMembersAvailable: 8,
				},
				{
					beginning: '2:00',
					end: '2:30',
					teamMembersAvailable: 8,
				},
				{
					beginning: '2:30',
					end: '3:00',
					teamMembersAvailable: 8,
				},
				{
					beginning: '3:00',
					end: '3:30',
					teamMembersAvailable: 8,
				},
				{
					beginning: '3:30',
					end: '4:00',
					teamMembersAvailable: 8,
				},
				{
					beginning: '4:00',
					end: '4:30',
					teamMembersAvailable: 7,
				},
				{
					beginning: '4:30',
					end: '5:00',
					teamMembersAvailable: 7,
				},
				{
					beginning: '5:00',
					end: '5:30',
					teamMembersAvailable: 0,
				},
				{
					beginning: '5:30',
					end: '6:00',
					teamMembersAvailable: 0,
				},
			],
			teamMembers: [
				{
					name: 'Anissa Quitzon',
					associate_id: 'B18ZOVGPZIE',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: true,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: true,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: false,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: false,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Cyrus Luettgen',
					associate_id: 'RQBXLIF0TA0',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: true,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: false,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Demarcus Huel',
					associate_id: 'G1DQZX1ONIG',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Ed McClure',
					associate_id: '7YLZCQOAKUG',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Hortense Brown',
					associate_id: 'UHO7NNNL8LP',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Jonas Yost',
					associate_id: 'AZBHNZ4PJKC',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Sheridan Hackett',
					associate_id: 'RQBXLIF0TA0',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Watson Streich',
					associate_id: 'RQBXLIF0TA0',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
			],
		},
		{
			day: 'Thursday',
			timeSlots: [
				{
					beginning: '8:00',
					end: '8:30',
					teamMembersAvailable: 1,
				},
				{
					beginning: '8:30',
					end: '9:00',
					teamMembersAvailable: 1,
				},
				{
					beginning: '9:00',
					end: '9:30',
					teamMembersAvailable: 8,
				},
				{
					beginning: '9:30',
					end: '10:00',
					teamMembersAvailable: 8,
				},
				{
					beginning: '10:00',
					end: '10:30',
					teamMembersAvailable: 8,
				},
				{
					beginning: '10:30',
					end: '11:00',
					teamMembersAvailable: 8,
				},
				{
					beginning: '11:00',
					end: '11:30',
					teamMembersAvailable: 1,
				},
				{
					beginning: '11:30',
					end: '12:00',
					teamMembersAvailable: 0,
				},
				{
					beginning: '12:00',
					end: '12:30',
					teamMembersAvailable: 7,
				},
				{
					beginning: '12:30',
					end: '1:00',
					teamMembersAvailable: 8,
				},
				{
					beginning: '1:00',
					end: '1:30',
					teamMembersAvailable: 8,
				},
				{
					beginning: '1:30',
					end: '2:00',
					teamMembersAvailable: 8,
				},
				{
					beginning: '2:00',
					end: '2:30',
					teamMembersAvailable: 8,
				},
				{
					beginning: '2:30',
					end: '3:00',
					teamMembersAvailable: 8,
				},
				{
					beginning: '3:00',
					end: '3:30',
					teamMembersAvailable: 8,
				},
				{
					beginning: '3:30',
					end: '4:00',
					teamMembersAvailable: 8,
				},
				{
					beginning: '4:00',
					end: '4:30',
					teamMembersAvailable: 7,
				},
				{
					beginning: '4:30',
					end: '5:00',
					teamMembersAvailable: 7,
				},
				{
					beginning: '5:00',
					end: '5:30',
					teamMembersAvailable: 0,
				},
				{
					beginning: '5:30',
					end: '6:00',
					teamMembersAvailable: 0,
				},
			],
			teamMembers: [
				{
					name: 'Anissa Quitzon',
					associate_id: 'B18ZOVGPZIE',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: true,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: true,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: false,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: false,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Cyrus Luettgen',
					associate_id: 'RQBXLIF0TA0',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: true,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: false,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Demarcus Huel',
					associate_id: 'G1DQZX1ONIG',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Ed McClure',
					associate_id: '7YLZCQOAKUG',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Hortense Brown',
					associate_id: 'UHO7NNNL8LP',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Jonas Yost',
					associate_id: 'AZBHNZ4PJKC',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Sheridan Hackett',
					associate_id: 'RQBXLIF0TA0',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Watson Streich',
					associate_id: 'RQBXLIF0TA0',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
			],
		},
		{
			day: 'Friday',
			timeSlots: [
				{
					beginning: '8:00',
					end: '8:30',
					teamMembersAvailable: 1,
				},
				{
					beginning: '8:30',
					end: '9:00',
					teamMembersAvailable: 1,
				},
				{
					beginning: '9:00',
					end: '9:30',
					teamMembersAvailable: 8,
				},
				{
					beginning: '9:30',
					end: '10:00',
					teamMembersAvailable: 8,
				},
				{
					beginning: '10:00',
					end: '10:30',
					teamMembersAvailable: 8,
				},
				{
					beginning: '10:30',
					end: '11:00',
					teamMembersAvailable: 8,
				},
				{
					beginning: '11:00',
					end: '11:30',
					teamMembersAvailable: 1,
				},
				{
					beginning: '11:30',
					end: '12:00',
					teamMembersAvailable: 0,
				},
				{
					beginning: '12:00',
					end: '12:30',
					teamMembersAvailable: 7,
				},
				{
					beginning: '12:30',
					end: '1:00',
					teamMembersAvailable: 8,
				},
				{
					beginning: '1:00',
					end: '1:30',
					teamMembersAvailable: 8,
				},
				{
					beginning: '1:30',
					end: '2:00',
					teamMembersAvailable: 8,
				},
				{
					beginning: '2:00',
					end: '2:30',
					teamMembersAvailable: 8,
				},
				{
					beginning: '2:30',
					end: '3:00',
					teamMembersAvailable: 8,
				},
				{
					beginning: '3:00',
					end: '3:30',
					teamMembersAvailable: 8,
				},
				{
					beginning: '3:30',
					end: '4:00',
					teamMembersAvailable: 8,
				},
				{
					beginning: '4:00',
					end: '4:30',
					teamMembersAvailable: 7,
				},
				{
					beginning: '4:30',
					end: '5:00',
					teamMembersAvailable: 7,
				},
				{
					beginning: '5:00',
					end: '5:30',
					teamMembersAvailable: 0,
				},
				{
					beginning: '5:30',
					end: '6:00',
					teamMembersAvailable: 0,
				},
			],
			teamMembers: [
				{
					name: 'Anissa Quitzon',
					associate_id: 'B18ZOVGPZIE',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: true,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: true,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: false,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: false,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Cyrus Luettgen',
					associate_id: 'RQBXLIF0TA0',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: true,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: false,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Demarcus Huel',
					associate_id: 'G1DQZX1ONIG',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Ed McClure',
					associate_id: '7YLZCQOAKUG',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Hortense Brown',
					associate_id: 'UHO7NNNL8LP',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Jonas Yost',
					associate_id: 'AZBHNZ4PJKC',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Sheridan Hackett',
					associate_id: 'RQBXLIF0TA0',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
				{
					name: 'Watson Streich',
					associate_id: 'RQBXLIF0TA0',
					timeSlots: [
						{
							beginning: '8:00',
							end: '8:30',
							available: false,
						},
						{
							beginning: '8:30',
							end: '9:00',
							available: false,
						},
						{
							beginning: '9:00',
							end: '9:30',
							available: true,
						},
						{
							beginning: '9:30',
							end: '10:00',
							available: true,
						},
						{
							beginning: '10:00',
							end: '10:30',
							available: true,
						},
						{
							beginning: '10:30',
							end: '11:00',
							available: true,
						},
						{
							beginning: '11:00',
							end: '11:30',
							available: false,
						},
						{
							beginning: '11:30',
							end: '12:00',
							available: false,
						},
						{
							beginning: '12:00',
							end: '12:30',
							available: true,
						},
						{
							beginning: '12:30',
							end: '1:00',
							available: true,
						},
						{
							beginning: '1:00',
							end: '1:30',
							available: true,
						},
						{
							beginning: '1:30',
							end: '2:00',
							available: true,
						},
						{
							beginning: '2:00',
							end: '2:30',
							available: true,
						},
						{
							beginning: '2:30',
							end: '3:00',
							available: true,
						},
						{
							beginning: '3:00',
							end: '3:30',
							available: true,
						},
						{
							beginning: '3:30',
							end: '4:00',
							available: true,
						},
						{
							beginning: '4:00',
							end: '4:30',
							available: true,
						},
						{
							beginning: '4:30',
							end: '5:00',
							available: true,
						},
						{
							beginning: '5:00',
							end: '5:30',
							available: false,
						},
						{
							beginning: '5:30',
							end: '6:00',
							available: false,
						},
					],
				},
			],
		},
		{
			day: 'Saturday',
			timeSlots: [
				{
					beginning: '8:00',
					end: '8:30',
					teamMembersAvailable: 0,
				},
				{
					beginning: '8:30',
					end: '9:00',
					teamMembersAvailable: 0,
				},
				{
					beginning: '9:00',
					end: '9:30',
					teamMembersAvailable: 0,
				},
				{
					beginning: '9:30',
					end: '10:00',
					teamMembersAvailable: 0,
				},
				{
					beginning: '10:00',
					end: '10:30',
					teamMembersAvailable: 0,
				},
				{
					beginning: '10:30',
					end: '11:00',
					teamMembersAvailable: 0,
				},
				{
					beginning: '11:00',
					end: '11:30',
					teamMembersAvailable: 0,
				},
				{
					beginning: '11:30',
					end: '12:00',
					teamMembersAvailable: 0,
				},
				{
					beginning: '12:00',
					end: '12:30',
					teamMembersAvailable: 0,
				},
				{
					beginning: '12:30',
					end: '1:00',
					teamMembersAvailable: 0,
				},
				{
					beginning: '1:00',
					end: '1:30',
					teamMembersAvailable: 0,
				},
				{
					beginning: '1:30',
					end: '2:00',
					teamMembersAvailable: 0,
				},
				{
					beginning: '2:00',
					end: '2:30',
					teamMembersAvailable: 0,
				},
				{
					beginning: '2:30',
					end: '3:00',
					teamMembersAvailable: 0,
				},
				{
					beginning: '3:00',
					end: '3:30',
					teamMembersAvailable: 0,
				},
				{
					beginning: '3:30',
					end: '4:00',
					teamMembersAvailable: 0,
				},
				{
					beginning: '4:00',
					end: '4:30',
					teamMembersAvailable: 0,
				},
				{
					beginning: '4:30',
					end: '5:00',
					teamMembersAvailable: 0,
				},
				{
					beginning: '5:00',
					end: '5:30',
					teamMembersAvailable: 0,
				},
				{
					beginning: '5:30',
					end: '6:00',
					teamMembersAvailable: 0,
				},
			],
		},
	],

	timeOff: [
		{
			pk: 0,
			ranged: false,
			date: '2025-07-04',
			startDate: null,
			endDate: null,
			repeatsAnnually: true,
			allDay: true,
			start: null,
			end: null,
			associateIds: [
				'B18ZOVGPZIE',
				'RQBXLIF0TA0',
				'G1DQZX1ONIG',
				'7YLZCQOAKUG',
				'UHO7NNNL8LP',
				'AZBHNZ4PJKC',
				'9G1YLUI8RFD',
				'JNDF1NSBLDJ',
			],
		},
		{
			pk: 1,
			ranged: false,
			date: '2024-12-25',
			startDate: null,
			endDate: null,
			repeatsAnnually: true,
			allDay: true,
			start: null,
			end: null,
			associateIds: [
				'B18ZOVGPZIE',
				'RQBXLIF0TA0',
				'G1DQZX1ONIG',
				'7YLZCQOAKUG',
				'UHO7NNNL8LP',
				'AZBHNZ4PJKC',
				'9G1YLUI8RFD',
				'JNDF1NSBLDJ',
			],
		},
		{
			pk: 3,
			ranged: false,
			date: '2024-09-11',
			startDate: null,
			endDate: null,
			repeatsAnnually: false,
			allDay: true,
			start: null,
			end: null,
			associateIds: [
				'G1DQZX1ONIG',
				'7YLZCQOAKUG',
				'UHO7NNNL8LP',
				'AZBHNZ4PJKC',
				'9G1YLUI8RFD',
			],
		},
		{
			pk: 4,
			ranged: false,
			date: '2024-10-17',
			startDate: null,
			endDate: null,
			repeatsAnnually: false,
			allDay: false,
			start: '14:00',
			end: '17:00',
			associateIds: ['UHO7NNNL8LP'],
		},
		{
			pk: 5,
			ranged: false,
			date: '2024-11-23',
			startDate: null,
			endDate: null,
			repeatsAnnually: false,
			allDay: false,
			start: '11:00',
			end: '17:00',
			associateIds: ['7YLZCQOAKUG'],
		},
	],
};

export const emptyCoverageView = [
	{
		day: 'Sunday',
		timeSlots: [
			{
				beginning: '8:00',
				end: '8:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '8:30',
				end: '9:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '9:00',
				end: '9:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '9:30',
				end: '10:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '10:00',
				end: '10:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '10:30',
				end: '11:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '11:00',
				end: '11:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '11:30',
				end: '12:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '12:00',
				end: '12:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '12:30',
				end: '1:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '1:00',
				end: '1:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '1:30',
				end: '2:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '2:00',
				end: '2:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '2:30',
				end: '3:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '3:00',
				end: '3:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '3:30',
				end: '4:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '4:00',
				end: '4:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '4:30',
				end: '5:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '5:00',
				end: '5:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '5:30',
				end: '6:00',
				teamMembersAvailable: 0,
			},
		],
	},
	{
		day: 'Monday',
		timeSlots: [
			{
				beginning: '8:00',
				end: '8:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '8:30',
				end: '9:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '9:00',
				end: '9:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '9:30',
				end: '10:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '10:00',
				end: '10:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '10:30',
				end: '11:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '11:00',
				end: '11:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '11:30',
				end: '12:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '12:00',
				end: '12:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '12:30',
				end: '1:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '1:00',
				end: '1:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '1:30',
				end: '2:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '2:00',
				end: '2:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '2:30',
				end: '3:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '3:00',
				end: '3:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '3:30',
				end: '4:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '4:00',
				end: '4:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '4:30',
				end: '5:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '5:00',
				end: '5:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '5:30',
				end: '6:00',
				teamMembersAvailable: 0,
			},
		],
	},
	{
		day: 'Tuesday',
		timeSlots: [
			{
				beginning: '8:00',
				end: '8:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '8:30',
				end: '9:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '9:00',
				end: '9:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '9:30',
				end: '10:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '10:00',
				end: '10:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '10:30',
				end: '11:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '11:00',
				end: '11:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '11:30',
				end: '12:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '12:00',
				end: '12:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '12:30',
				end: '1:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '1:00',
				end: '1:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '1:30',
				end: '2:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '2:00',
				end: '2:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '2:30',
				end: '3:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '3:00',
				end: '3:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '3:30',
				end: '4:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '4:00',
				end: '4:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '4:30',
				end: '5:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '5:00',
				end: '5:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '5:30',
				end: '6:00',
				teamMembersAvailable: 0,
			},
		],
	},
	{
		day: 'Wednesday',
		timeSlots: [
			{
				beginning: '8:00',
				end: '8:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '8:30',
				end: '9:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '9:00',
				end: '9:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '9:30',
				end: '10:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '10:00',
				end: '10:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '10:30',
				end: '11:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '11:00',
				end: '11:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '11:30',
				end: '12:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '12:00',
				end: '12:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '12:30',
				end: '1:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '1:00',
				end: '1:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '1:30',
				end: '2:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '2:00',
				end: '2:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '2:30',
				end: '3:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '3:00',
				end: '3:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '3:30',
				end: '4:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '4:00',
				end: '4:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '4:30',
				end: '5:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '5:00',
				end: '5:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '5:30',
				end: '6:00',
				teamMembersAvailable: 0,
			},
		],
	},
	{
		day: 'Thursday',
		timeSlots: [
			{
				beginning: '8:00',
				end: '8:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '8:30',
				end: '9:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '9:00',
				end: '9:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '9:30',
				end: '10:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '10:00',
				end: '10:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '10:30',
				end: '11:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '11:00',
				end: '11:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '11:30',
				end: '12:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '12:00',
				end: '12:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '12:30',
				end: '1:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '1:00',
				end: '1:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '1:30',
				end: '2:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '2:00',
				end: '2:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '2:30',
				end: '3:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '3:00',
				end: '3:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '3:30',
				end: '4:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '4:00',
				end: '4:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '4:30',
				end: '5:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '5:00',
				end: '5:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '5:30',
				end: '6:00',
				teamMembersAvailable: 0,
			},
		],
	},
	{
		day: 'Friday',
		timeSlots: [
			{
				beginning: '8:00',
				end: '8:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '8:30',
				end: '9:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '9:00',
				end: '9:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '9:30',
				end: '10:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '10:00',
				end: '10:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '10:30',
				end: '11:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '11:00',
				end: '11:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '11:30',
				end: '12:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '12:00',
				end: '12:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '12:30',
				end: '1:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '1:00',
				end: '1:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '1:30',
				end: '2:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '2:00',
				end: '2:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '2:30',
				end: '3:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '3:00',
				end: '3:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '3:30',
				end: '4:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '4:00',
				end: '4:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '4:30',
				end: '5:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '5:00',
				end: '5:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '5:30',
				end: '6:00',
				teamMembersAvailable: 0,
			},
		],
	},
	{
		day: 'Saturday',
		timeSlots: [
			{
				beginning: '8:00',
				end: '8:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '8:30',
				end: '9:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '9:00',
				end: '9:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '9:30',
				end: '10:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '10:00',
				end: '10:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '10:30',
				end: '11:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '11:00',
				end: '11:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '11:30',
				end: '12:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '12:00',
				end: '12:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '12:30',
				end: '1:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '1:00',
				end: '1:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '1:30',
				end: '2:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '2:00',
				end: '2:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '2:30',
				end: '3:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '3:00',
				end: '3:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '3:30',
				end: '4:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '4:00',
				end: '4:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '4:30',
				end: '5:00',
				teamMembersAvailable: 0,
			},
			{
				beginning: '5:00',
				end: '5:30',
				teamMembersAvailable: 0,
			},
			{
				beginning: '5:30',
				end: '6:00',
				teamMembersAvailable: 0,
			},
		],
	},
];
